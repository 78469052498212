@import '../node_modules/zp-ui/styles/root';
@import '../node_modules/zp-ui/styles/func';

:root {
  --text: #222;
  --text-dark-mode: #f5f5f5;
  --background: #fff;
  --background-dark-mode: #424242;
  --anchor: #00e;
  --anchor-dark-mode: #e39777;
}

body {
  color: var(--text);
  font-size: torem(14px);
  background-color: var(--background);
}

a {
  color: var(--anchor);
}

// @link https://tombrow.com/dark-mode-website-css
@media (prefers-color-scheme: dark) {
  body {
    color: var(--text-dark-mode);
    background-color: var(--background-dark-mode);
  }

  a {
    color: var(--anchor-dark-mode);
  }

  img {
    filter: grayscale(30%);
  }
}

.container {
  width: 100%;
  align-self: center;
}

@media only screen and (min-width: 1001px) {
  .container {
    width: 95%;
  }
}

#app {
  @include flexbox(column, nowrap, flex-start, flex-start);

  width: 100vw;
  min-height: 100vh;

  .header {
    @include flexbox(row, nowrap, flex-start, center);

    flex: 0 0 auto;
    font-size: torem(20px);
    font-weight: bold;
    padding: torem(10px) torem(5px);

    .logo {
      flex: 0 0 auto;
      width: torem(40px);
      height: torem(40px);
      margin-right: torem(5px);
    }
  }

  .anchors {
    flex: 0 0 auto;
    padding-left: torem(50px);
  }

  .title {
    flex: 0 0 auto;
    padding-left: torem(10px);
  }

  .desc {
    flex: 0 0 auto;
    padding: 0 torem(10px);
  }

  .sw {
    flex: 0 0 auto;
    padding: 0 torem(10px);
  }

  .footer {
    flex: 0 0 auto;
    padding: torem(10px);
  }
}
